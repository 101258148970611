$(document).ready(function() {
    let header = $('.header');
    let logo = $('.logo__image');

    // If data-transparency is true but header has not transparent class, add it.
    if (!header.hasClass('transparent') && header.data('transparency') == "transparent") {
        header.addClass('transparent');
    }

    if (header.hasClass('transparent')) {
        let section  = $('main').find('.section').first();

        if (section.css('background-image') != "none") {
            let image = section.css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");
            getImageBrightness(image, function(result) {
                if (result > 127.5) setBrightness('light');
                else setBrightness('dark');
            });
        } else {
            let color = section.css('background-color');
            setBrightness(getColorBrightness(color));
        }

    } else {

        // Define wether the font is bright or dark
        let color = header.css('background-color');
        let brightness = getColorBrightness(color);
        setBrightness(brightness);
    }

    $(window).scroll(function() {
        let scroll = $(window).scrollTop();

        if (scroll > 1) {
            header.addClass('fixed');

            if (header.data('transparency') == "transparent") {
                header.removeClass('transparent');

                let color = header.css('background-color');
                setBrightness(getColorBrightness(color));
            }
        } else {
            header.removeClass('fixed');

            if (header.data('transparency') == "transparent") {
                header.addClass('transparent');
                let section  = $('main').find('.section').first();

                if (section.css('background-image') != "none") {
                    let image = section.css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");
                    getImageBrightness(image, function(result) {
                        if (result > 127.5) setBrightness('light');
                        else setBrightness('dark');
                    });
                } else {
                    let color = section.css('background-color');
                    setBrightness(getColorBrightness(color));
                }
            }
        }
    });
});

function setBrightness(brightness) {
    let header = $('.header');
    let logo = $('.logo__image');

    if (brightness == "light") {
        header.removeClass('light');
        logo.attr('src', logo.data('src-black'));
    } else if (brightness == "dark") {
        header.addClass('light');
        logo.attr('src', logo.data('src-white'));
    }
}

function getColorBrightness(color) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {
        return 'light';
    } else {
        return 'dark';
    }
}

function getImageBrightness(imageSrc, callback) {
    var img = document.createElement("img");
    img.src = imageSrc;
    img.style.display = "none";
    document.body.appendChild(img);

    var colorSum = 0;
    var brightness = 0;

    img.onload = function() {
        // create canvas
        var canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(this,0,0);

        var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
        var data = imageData.data;
        var r,g,b,avg;

          for(var x = 0, len = data.length; x < len; x+=4) {
            r = data[x];
            g = data[x+1];
            b = data[x+2];

            avg = Math.floor((r+g+b)/3);
            colorSum += avg;
        }

        brightness = Math.floor(colorSum / (this.width*this.height));

        callback(brightness);
    }
}