/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/app.scss';
// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';
global.$ = global.jQuery = $;

import '../../../admin/plugins/select2/js/select2';
import '../../../admin/plugins/select2/css/select2.css';
import './header.js';

$(document).ready(function() {
    $('.categorySelect').select2({
        dropdownParent: $(".category"),
        minimumResultsForSearch: 100,
      });
});